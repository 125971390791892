@import url("https://fonts.googleapis.com/css?family=Nunito");

.attachments > dt {
  color: #1c7d63;
  margin: 2rem 0 1rem;
}

.attachments > dd > ul {
  list-style: none !important;
  padding-inline-start: 0 !important;
}

.attachments > dd > ul > li {
  margin-bottom: 1rem;
}

.attachments > dd > ul > li:last-of-type {
  margin-bottom: 0;
}

.pagination {
  text-align: center;
}

.pagination li {
  display: inline-block;
}

.pagination span {
  background-color: #aaa;
  color: #fff;
  display: inline-block;
  padding: 5px 15px;
  position: relative;
}

.pagination a {
  background: linear-gradient(90deg, #119b52 0%, #1c7d63 100%);
  color: #eeedf2;
  display: inline-block;
  padding: 5px 15px;
  position: relative;
  transition: all 400ms;
}

.fixed-news {
  background: linear-gradient(90deg, #119b52 0%, #1c7d63 100%);
  padding: 4em 0;
  overflow: hidden;
}

.fixed-news .news-item {
  max-width: 970px;
  width: 70vw;
  margin: 0 40px;
  transition: all 400ms;
  background-color: #f0f0f0;
}

.fixed-news .arrow-prev {
  margin-left: -10vw;
}

.entry-list {
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
}

.entry-list .entry-item {
  background-color: #1c7d63;
  color: #fff;
  float: left;
  height: 0;
  margin-bottom: 10px;
  overflow: hidden;
  padding-top: 20%;
  position: relative;
  text-align: center;
  width: calc((100% - 20px) / 3);
}

.entry-list .entry-item > a {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.entry-list .entry-item > a::before {
  background: linear-gradient(145deg, rgba(255, 255, 255, 0) 0%, #1d9a52 50%, white 100%);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  opacity: 0.7;
  transform: translate3d(0%, 0, 0);
  transition: all 400ms;
}

.entry-list .entry-item > a span {
  color: #fff;
  position: relative;
  z-index: 1;
}

.entry-list .entry-item > a .entry-item-title-jp {
  letter-spacing: 0.35em;
}

.entry-list .entry-item > a span:nth-child(1) {
  padding: 10px 10px 0;
}

.entry-list .entry-item > a span:nth-child(2) {
  font-size: 1.8rem;
  padding: 0 10px 10px;
  transform: translate3d(0%, 0, 0);
}

.entry-list .entry-item > a:hover {
  transform: scale(1.1);
}

.entry-list .entry-item > a:hover::before {
  transform: translate3d(-50%, 0, 0);
}

.entry-list .entry-item span {
  display: block;
}

@media (max-width: 700px) {
  .entry-list .entry-item {
    padding-top: 30%;
    width: 100%;
  }

  .entry-list .entry-item > a span:nth-child(1) {
    font-size: 1.2rem;
  }

  .entry-list .entry-item > a span:nth-child(2) {
    font-size: 1.4rem;
  }
}

