// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Bootstrap
//@import '~bootstrap/scss/bootstrap';
// js-datepicker
//@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

// -------------------------------------------------------news
// åå¥ãã¼ã¸
// -------------------------------------------------------------

// news
// -------------------------------------------------------------

.attachments {
	> dt {
		color: $mj-green1;
		margin: 2rem 0 1rem;
	}

	> dd > ul {
		list-style: none !important;
		padding-inline-start: 0 !important;

		> li {
			margin-bottom: 1rem;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

.pagination {
	text-align: center;

	li {
		display: inline-block;
	}

	span {
		background-color: $mj-gray1;
		color: $mj-white1;
		display: inline-block;
		padding: 5px 15px;
		position: relative;
	}

	a {
		background: linear-gradient(90deg, #119b52 0%, #1c7d63 100%);
		color: $mj-white2;
		display: inline-block;
		padding: 5px 15px;
		position: relative;
		transition: all 400ms;
	}
}

.fixed-news {
    background: linear-gradient(90deg, #119b52 0%, #1c7d63 100%);
    padding: 4em 0;
    overflow: hidden;

  .news-item {
    max-width: 970px;
    width: 70vw;
    margin: 0 40px;
    transition: all 400ms;
    background-color: #f0f0f0;
  }

  .arrow-prev {
    margin-left: -10vw;

  }
}

// entry
// -------------------------------------------------------------

.entry-list {
  background-color: rgba(0, 0, 0, .02);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;

  .entry-item {
    background-color: $mj-green1;
    color: $mj-white1;
    float: left;
    height: 0;
    margin-bottom: 10px;
    overflow: hidden;
    padding-top: 20%;
    position: relative;
    text-align: center;
    width: calc((100% - 20px) / 3);

    > a {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    > a::before {
      background: linear-gradient(145deg, rgba(255,255,255,0) 0%, rgba(29,154,82,1) 50%, rgba(255,255,255,1) 100%);
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      opacity: .7;
      transform: translate3d(0%, 0, 0);
      transition: all 400ms;
    }

    > a span {
      color: $mj-white1;
      position: relative;
      z-index: 1;
    }

    > a .entry-item-title-jp {
      letter-spacing: .35em;
    }

    > a span:nth-child(1) {
      padding: 10px 10px 0;
    }

    > a span:nth-child(2) {
      font-size: 1.8rem;
      padding: 0 10px 10px;
      transform: translate3d(0%, 0, 0);
    }

    > a:hover {
      transform: scale(1.1);
    }

    > a:hover::before {
      transform: translate3d(-50%, 0, 0);
    }

    span {
      display: block;
    }
  }
}

@media (max-width: 700px) {
  .entry-list .entry-item {
    padding-top: 30%;
    width: 100%;

    & > a span:nth-child(1) {
      font-size: 1.2rem;
    }

    & > a span:nth-child(2) {
      font-size: 1.4rem;
    }
  }
}
